<script setup lang="ts">
import type { BlockCustomFields } from '~/types/models/cms/cmsBlockTypes';

const props = withDefaults(
    defineProps<{
        customFields?: BlockCustomFields | null;
    }>(),
    {
        customFields: null,
    },
);

const { isLoggedIn } = useUser();
const isContentVisible = computed<boolean>(() => !props.customFields?.restrictedToLoggedIn || isLoggedIn.value);
</script>

<template>
    <slot v-if="isContentVisible" />
</template>
